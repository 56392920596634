import React, { useEffect } from 'react';
import classNames from 'classnames';
// @ts-expect-error
import { GatewayDest, GatewayProvider } from 'react-gateway';
import { getIsCreatedWithResponsiveEditor } from '../../selectors/app-settings-selectors';
import { isInWix } from '../../services/is-in-wix';
import { AccessibilityListener } from '../accessibility-listener';
import AppWrapper from '../app-wrapper';
import { useSelector } from '../runtime-context';
import styles from './app.scss';

type Props = {
  main?: React.FunctionComponentElement<{ children?: React.ReactNode }>;
  mobileHeader?: React.FunctionComponentElement<{
    menu?: React.FunctionComponentElement<any>;
    hideNavBar?: boolean;
  }>;
  mobileMenu?: React.FunctionComponentElement<any>;
  messageRoot?: React.FunctionComponentElement<any>;
  modalRoot?: React.FunctionComponentElement<any>;
  popoverRoot?: React.FunctionComponentElement<any>;
};

const AppMobile: React.FC<Props> = ({
  modalRoot,
  messageRoot,
  popoverRoot,
  mobileHeader,
  mobileMenu,
  main,
  children,
}) => {
  const createdWithResponsiveEditor = useSelector(
    getIsCreatedWithResponsiveEditor,
  );

  useEffect(() => {
    if (!isInWix()) {
      document.documentElement.classList.add('enable-scroll');
    }
  }, []);

  return (
    <GatewayProvider>
      <AppWrapper
        className={classNames(
          styles.app,
          'app-mobile',
          'blog-background-color',
        )}
      >
        <AccessibilityListener />
        {messageRoot}
        {modalRoot}
        {popoverRoot}
        <div
          id="content-wrapper"
          className={classNames(styles.scrollable, 'app-mobile__scrollable')}
        >
          {mobileHeader &&
            React.cloneElement(mobileHeader, {
              menu: mobileMenu,
              hideNavBar: createdWithResponsiveEditor,
            })}
          {main && React.cloneElement(main, { children })}
        </div>
        <GatewayDest name="tooltip" />
      </AppWrapper>
    </GatewayProvider>
  );
};

export default AppMobile;
